import { h } from "virtual-dom";
import { createWidget } from "discourse/widgets/widget";

export default createWidget("time-gap", {
  tagName: "div.time-gap.small-action",

  html() {
    return [h("div.topic-avatar", "")];
  },
});
