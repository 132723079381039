import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { fn } from "@ember/helper";
import { action } from "@ember/object";
import DButton from "discourse/components/d-button";
import concatClass from "discourse/helpers/concat-class";
export default class OptionPostMenu extends Component {
    buttons;
    constructor(){
        super(...arguments);
        this.buttons = this.args.data.buttons;
    }
    @action
    async buttonAction(button1) {
        await this.args.close();
        await button1.action(this.args.data.post);
    }
    static{
        template(`
    <ul>
      {{#each this.buttons as |button|}}
        <li>
          <DButton
            @label={{button.label}}
            @translatedLabel={{button.translatedLabel}}
            @icon={{button.icon}}
            class={{concatClass "btn popup-menu-btn" button.className}}
            @action={{fn this.buttonAction button}}
          />
        </li>
      {{/each}}
    </ul>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
