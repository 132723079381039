/* import __COLOCATED_TEMPLATE__ from './topic-title.hbs'; */
import Component from "@ember/component";
import EmberObject from "@ember/object";
import { schedule } from "@ember/runloop";
import { popupAjaxError } from "discourse/lib/ajax-error";
import KeyEnterEscape from "discourse/mixins/key-enter-escape";
import Topic from "discourse/models/topic";
import discourseComputed from "discourse-common/utils/decorators";

export let topicTitleDecorators = [];

export function addTopicTitleDecorator(decorator) {
  topicTitleDecorators.push(decorator);
}

export function resetTopicTitleDecorators() {
  topicTitleDecorators.length = 0;
}

export default Component.extend(KeyEnterEscape, {
  elementId: "topic-title",
  uploaded_cover_image: null,

  didInsertElement() {
    this._super(...arguments);

    schedule("afterRender", () => {
      if (this.element && !this.isDestroying && !this.isDestroyed) {
        const fancyTitle = this.element.querySelector(".fancy-title");

        fancyTitle &&
          topicTitleDecorators &&
          topicTitleDecorators.forEach((cb) =>
            cb(this.model, fancyTitle, "topic-title")
          );
      }
    });
  },

  @discourseComputed("uploaded_cover_image.url")
  logoImageUrl(uploadedLogoUrl) {
    return uploadedLogoUrl || "";
  },

  @discourseComputed("model.details.can_edit")
  userEditDisabled() {
    return !this.model.details.can_edit;
  },

  actions: {
    logoUploadDone(upload) {
      this._setFromUpload("uploaded_cover_image", upload);
      this._updateTopicCoverImage();
    },

    logoUploadDeleted() {
      this._deleteUpload("uploaded_cover_image");
      this._updateTopicCoverImage();
    },
  },

  _setFromUpload(path, upload) {
    this.set(
      path,
      EmberObject.create({
        url: upload.url,
        id: upload.id,
      })
    );
  },

  _deleteUpload(path) {
    this.set(
      path,
      EmberObject.create({
        id: null,
        url: null,
      })
    );
  },

  _updateTopicCoverImage() {
    Topic.update(
      this.model,
      { cover_image_id: this.uploaded_cover_image?.id || null },
      { fastEdit: true }
    ).catch(popupAjaxError);
  },
});
