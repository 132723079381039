/* import __COLOCATED_TEMPLATE__ from './parent-category-row.hbs'; */
import CategoryListItem from "discourse/components/category-list-item";
import discourseComputed from "discourse-common/utils/decorators";

export default CategoryListItem.extend({
  @discourseComputed("category")
  topicCount() {
    let totalTopicCount = this.category.topic_count;
    if (this.category.subcategories.length > 0) {
      totalTopicCount += this.category.subcategories.reduce(
        (total, item) => total + item.topic_count,
        0
      );
    }
    return totalTopicCount;
  },
});
