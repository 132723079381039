import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";

let template = compiler({"1":function(container,depth0,helpers,partials,data) {
    return " pinned-topic";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <img class=\"topic-avatar-image\" src="
    + container.escapeExpression(lookupProperty(helpers,"get").call(depth0 != null ? depth0 : (container.nullContext || {}),"topic.cover_image.url",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":10,"column":46},"end":{"line":10,"column":71}}}))
    + " alt=\"\" />\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "          <img class=\"topic-avatar-image placeholder-image\" alt=\"\" />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"topic-featured-link")||(depth0 && lookupProperty(depth0,"topic-featured-link"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic",{"name":"topic-featured-link","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":30,"column":10},"end":{"line":30,"column":41}}}));
},"9":function(container,depth0,helpers,partials,data) {
    return "<span class=\"topic-post-badges\">&nbsp;<span class=\"badge-notification new-topic\"></span></span>";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <label for=\"bulk-select-"
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.id",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":41,"column":34},"end":{"line":41,"column":46}}}))
    + "\">\n            <input type=\"checkbox\" class=\"bulk-select\" id=\"bulk-select-"
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.id",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":42,"column":71},"end":{"line":42,"column":83}}}))
    + "\">\n          </label>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"avatar-badge\">      \n            <a href=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.lastPostUrl",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":46,"column":21},"end":{"line":46,"column":42}}}))
    + "\" aria-label=\""
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias3).call(alias1,"latest_poster_link",{"name":"i18n","hash":{"username":"topic.lastPosterUser.username"},"hashTypes":{"username":"PathExpression"},"hashContexts":{"username":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":46,"column":56},"end":{"line":46,"column":124}}}))
    + "\" data-user-card=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.lastPosterUser.username",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":46,"column":142},"end":{"line":46,"column":175}}}))
    + "\">"
    + alias2((lookupProperty(helpers,"avatar")||(depth0 && lookupProperty(depth0,"avatar"))||alias3).call(alias1,"topic.lastPosterUser",{"name":"avatar","hash":{"imageSize":"small"},"hashTypes":{"imageSize":"StringLiteral"},"hashContexts":{"imageSize":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":46,"column":177},"end":{"line":46,"column":226}}}))
    + "</a>\n          </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-before-category"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":64,"column":12},"end":{"line":64,"column":68}}}))
    + "\n            "
    + alias3((lookupProperty(helpers,"category-link")||(depth0 && lookupProperty(depth0,"category-link"))||alias2).call(alias1,"topic.category",{"name":"category-link","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":65,"column":12},"end":{"line":65,"column":45}}}));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),"site.mobileView",{"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(18, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":71,"column":28},"end":{"line":73,"column":19}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"raw")||(depth0 && lookupProperty(depth0,"raw"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"list.topic-excerpt",{"name":"raw","hash":{"topic":"topic"},"hashTypes":{"topic":"PathExpression"},"hashContexts":{"topic":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":72,"column":8},"end":{"line":72,"column":50}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"topic-list-data\">"
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-before-columns"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":57}}}))
    + "\n  <div class='topic-item-metadata' >\n    <div class=\"left-side\">\n      <div class=\"color-strap-wrap\">\n        <div class=\"color-strap "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.pinned",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(1, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":72}}})) != null ? stack1 : "")
    + "\" style='background-color: #"
    + alias3(lookupProperty(helpers,"get").call(alias1,"topic.category.color",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":6,"column":100},"end":{"line":6,"column":124}}}))
    + "'></div>\n      </div>\n      <div class=\"image-wrap\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.cover_image.url",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"content-wrap\" >\n"
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-before-link"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":24,"column":6},"end":{"line":24,"column":58}}}))
    + "\n      <div class='topic-item-metadata-first-row'>\n        <div class='main-link'>"
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-before-status"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":27,"column":10},"end":{"line":27,"column":64}}}))
    + alias3((lookupProperty(helpers,"topic-link")||(depth0 && lookupProperty(depth0,"topic-link"))||alias2).call(alias1,"topic",{"name":"topic-link","hash":{"class":"raw-link raw-topic-link"},"hashTypes":{"class":"StringLiteral"},"hashContexts":{"class":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":28,"column":10},"end":{"line":28,"column":63}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.featured_link",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(7, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":29,"column":10},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-after-title"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":32,"column":10},"end":{"line":32,"column":62}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.unseen",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(9, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":33,"column":10},"end":{"line":35,"column":19}}})) != null ? stack1 : "")
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-main-link-bottom"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":36,"column":10},"end":{"line":36,"column":67}}}))
    + "\n        </div>\n      \n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"bulkSelectEnabled",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "")
    + "\n\n        "
    + alias3((lookupProperty(helpers,"raw")||(depth0 && lookupProperty(depth0,"raw"))||alias2).call(alias1,"list.post-count-or-badges",{"name":"raw","hash":{"postBadgesEnabled":"showTopicPostBadges","topic":"topic"},"hashTypes":{"postBadgesEnabled":"PathExpression","topic":"PathExpression"},"hashContexts":{"postBadgesEnabled":depth0,"topic":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":51,"column":93}}}))
    + "\n\n\n        <div class='num activity last'>\n          <span class=\"age activity\" title=\""
    + alias3(lookupProperty(helpers,"get").call(alias1,"topic.bumpedAtTitle",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":55,"column":44},"end":{"line":55,"column":67}}}))
    + "\"><a\n              href=\""
    + alias3(lookupProperty(helpers,"get").call(alias1,"topic.lastPostUrl",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":56,"column":41}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"format-date")||(depth0 && lookupProperty(depth0,"format-date"))||alias2).call(alias1,"topic.bumpedAt",{"name":"format-date","hash":{"noTitle":"true","format":"tiny"},"hashTypes":{"noTitle":"StringLiteral","format":"StringLiteral"},"hashContexts":{"noTitle":depth0,"format":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":56,"column":43},"end":{"line":56,"column":102}}}))
    + "</a>\n          </span>\n        </div>\n\n      </div>\n      <div class=\"topic-item-stats clearfix\">\n        <span class=\"topic-item-stats__category-tags\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,"hideCategory",{"name":"unless","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(15, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":63,"column":10},"end":{"line":66,"column":22}}})) != null ? stack1 : "")
    + alias3((lookupProperty(helpers,"discourse-tags")||(depth0 && lookupProperty(depth0,"discourse-tags"))||alias2).call(alias1,"topic",{"name":"discourse-tags","hash":{"mode":"list"},"hashTypes":{"mode":"StringLiteral"},"hashContexts":{"mode":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":67,"column":10},"end":{"line":67,"column":47}}}))
    + "\n        </span>\n      </div>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"expandPinned",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(17, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":71,"column":6},"end":{"line":73,"column":28}}})) != null ? stack1 : "")
    + "</div>\n  </div>\n</td>\n";
},"useData":true});

addRawTemplate("mobile/list/topic-list-item", template, { core: true });
export default template;